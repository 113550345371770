import { 
    Box, 
    Button, 
    Flex, 
    HStack, 
    Icon, 
    Text, 
    useMediaQuery 
} from '@chakra-ui/react'
import React from 'react'
import { 
    BlackArrow, 
    ContactChat, 
    ContactMail, 
    ContactPhone, 
    Facebook, 
    Instagram, 
    Linkedin, 
    Play, 
    Twitter, 
} from '../../../Assets'
import { useNavigate } from 'react-router-dom'

const Contact = () => {

    const navigate = useNavigate();    
    const [isSm] = useMediaQuery(`(max-width: 992px)`);
    const [isXs] = useMediaQuery(`(max-width: 760px)`);
    const direction = isSm ? "column" : "row";
    const vert = isXs ? "column" : "row";

  return (
    <Box py={8} >
        
        <Box backgroundColor={'primary.100'} borderRadius={'3xl'} align={'center'} p={8} w={'90%'} margin={'auto'}   >
            <Text color={'brand.300'} fontSize={['2xl','3.5xl','5xl']} fontWeight={'bold'} >
                Onboard With Us Today <br /> And Start Your Transactions
            </Text>
            <HStack flexDir={direction}  py={8} alignItems={'center'} justifyContent={'center'}  >
                <Button
                    bg="primary.100" 
                    color="primary.900" 
                    p="25px 30px" 
                    h="48px" 
                    border={'1px solid #E8EEFF'}
                    borderRadius={'2xl'}
                    // width="40%" 
                    // mr={'auto'}
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'primary.100',
                    color: 'none',}}                  
                    onClick={() => navigate('/user_type') }
                >
                    Get Started
                    <Icon boxSize='30px' viewBox="0 0 50 50" mt={'20px'} ml={'10px'}  >
                        <BlackArrow/>
                    </Icon> 
                </Button>

                <Button
                    bg="primary.100" 
                    color="primary.900" 
                    p="25px 30px" 
                    h="48px" 
                    border={'1px solid #E8EEFF'}
                    borderRadius={'2xl'}
                    mt={isSm ? 7 : null }
                    // width="40%" 
                    // mr={'auto'}
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'primary.100',
                    color: 'none',}}
                    onClick={() => window.open('https://drive.google.com/file/d/1PTGb_gOJpe6ZWCaIeyhReLacYLjrRaDs/view?ts=66b5e808', '_blank')} 
                >
                   <Icon boxSize='40px' viewBox="0 0 50 50" mt={'18px'} ml={'10px'}  >
                        <Play/>
                    </Icon> 
                    Watch Demo
                </Button>
            </HStack>
            <Box>
               
                <Text color={'font.300'} fontSize={'sm'} py={5} >
                    EasyGas is your all-in-one solution for streamlined LPG experiences. 
                </Text>
                <HStack flexDir={direction} alignItems={'center'} justifyContent={ isSm ? 'none' : 'center'} py={5} color={'#fff'} > 
                    <Flex alignItems={'center'} mr={isSm ? 5 : null} >
                        <Icon boxSize='40px' viewBox="0 0 50 50" >
                            <ContactPhone/>
                        </Icon> 
                        <Box textAlign={'left'} ml={'5'} >
                            <Text fontWeight={'semibold'} fontSize={'lg'}  >Call Us</Text>
                            <Text>(234) 7052906307 </Text>
                        </Box>
                    </Flex>
                    <Flex alignItems={'center'} mr={isSm ? 5 : null } mx={ isSm ? null : 10} >
                        <Icon boxSize='40px' viewBox="0 0 50 50" >
                            <ContactChat/>
                        </Icon> 
                        <Box textAlign={'left'} ml={'5'}  >
                            <Text fontWeight={'semibold'} fontSize={'lg'}>Chat With Us</Text>
                            <Text>(234) 8097067857 </Text>
                        </Box>
                    </Flex>
                    <Flex alignItems={'center'} >
                        <Icon boxSize='40px' viewBox="0 0 50 50" >
                            <ContactMail/>
                        </Icon> 
                        <Box textAlign={'left'} ml={'5'}  >
                            <Text fontWeight={'semibold'} fontSize={'lg'}>Email Us</Text>
                            <Text>elijah.e@myeasygas.com</Text>
                        </Box>
                    </Flex>
                </HStack>
                
                <Text color={'font.300'} py={5} >
                    49 Adeyeri Estate, Ogba, Lagos Nigeria.                
                </Text>
            </Box>
            <HStack flexDir={vert} alignItems={'center'} justifyContent={'space-between'} borderTop={'1px solid #B5B4B4'} pt={2} >
                <Text color={'border.200'} order={isXs ? 2 : 1} >
                    © 2024 EasyGas. All Rights Reserved.
                </Text>
                <HStack alignItems={'center'} mt={5} >
                    <Icon boxSize='50px' viewBox="0 0 50 50" >
                        <Facebook/>
                    </Icon> 
                    <Icon boxSize='50px' viewBox="0 0 50 50" >
                        <Linkedin/>
                    </Icon> 
                    <Icon boxSize='45px' viewBox="0 0 50 50" >
                        <Twitter/>
                    </Icon> 
                    <Icon boxSize='50px' viewBox="0 0 50 50" >
                        <Instagram/>
                    </Icon> 
                </HStack>
            </HStack>
        </Box>
        {/* <Flex >
        <Box w={'50%'} px={8} >
            <Text fontWeight={'bold'} fontSize={'3xl'} color={'font.900'} pt={10} align={'center'} py={5} >
                Contact Our <Text as={'span'} color={'border.300'}>Support</Text>
            </Text>
            <Text color={'primary.300'} >
                Have questions or need assistance? Our support team is here to help. Reach out to us through the channels below.
            </Text>
            <Box py={7} >
                <HStack alignItems={'center'} justifyContent={'space-between'} py={5} > 
                    <Box w={'50%'} >
                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                            <ContactPhone/>
                        </Icon> 
                        <Text color={'primary.800'} fontWeight={'semibold'} fontSize={'lg'}  >Call Us</Text>
                        <Text>(234) 902-4283-284</Text>
                    </Box>
                    <Box w={'50%'}>
                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                            <ContactChat/>
                        </Icon> 
                        <Text color={'primary.800'} fontWeight={'semibold'} fontSize={'lg'}>Chat With Us</Text>
                        <Text>(234) 902-4283-284</Text>
                    </Box>
                </HStack>
                <HStack alignItems={'center'} justifyContent={'space-between'} py={5}>
                    <Box w={'50%'}>
                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                            <ContactMail/>
                        </Icon> 
                        <Text color={'primary.800'} fontWeight={'semibold'} fontSize={'lg'}>Email Us</Text>
                        <Text>support@easygaz.com</Text>
                    </Box>
                    <Box w={'50%'}>
                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                            <ContactLike/>
                        </Icon> 
                        <Text color={'primary.800'} fontWeight={'semibold'} fontSize={'lg'}>Follow Us On</Text>
                        <HStack>
                            <Icon boxSize='25px' viewBox="0 0 50 50" >
                                <Facebook/>
                            </Icon> 
                            <Icon boxSize='25px' viewBox="0 0 50 50" >
                                <Linkedin/>
                            </Icon> 
                            <Icon boxSize='25px' viewBox="0 0 50 50" >
                                <Twitter/>
                            </Icon> 
                            <Icon boxSize='25px' viewBox="0 0 50 50" >
                                <Instagram/>
                            </Icon> 
                        </HStack>
                    </Box>
                </HStack>
            </Box>
        </Box>
        <Box w={'50%'} position={'relative'} >
            <Image
                ml={'auto'}
                // boxSize='lg'
                w={'100%'}
                // h={'300px'}
                objectFit='cover' src={ContactImg} 
            /> 
            <Box position={'absolute'} top={'40px'} left={'90px'}  >
                <Icon boxSize='50px' viewBox="0 0 80 80"  >
                    <Periwinkle/>
                </Icon> 
            </Box>
            <Box position={'absolute'} top={'40px'} right={'70px'}  >
                <Icon boxSize='50px' viewBox="0 0 80 80"  >
                    <ContStar/>
                </Icon> 
            </Box>
        </Box>
        </Flex> */}
    </Box>

  )
}

export default Contact