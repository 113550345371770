import { Box,  Flex, HStack,  Image, Modal, ModalBody, ModalContent,  ModalOverlay, Table, TableContainer,  Tbody, Td, Text, Th, Thead, Tr,  useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react'
import  EmeryAva  from '../../../Assets/EmeryAva.svg';
import  BlueTick  from '../../../Assets/BlueTick.svg';
import FullLogo from '../../../Assets/Full-Logo.svg';
import LoadingOrderLogo from '../../../Assets/LoadingOrderLogo.svg';
import Company from '../../../Assets/Company.svg';
import Element from '../../../Assets/Element.svg';
import { LoadingOrder, LoadingOrderFooter} from '../../../Assets';


const AllOrder = ({orderData,isLoadingData,isErrorData,tableData}) => {
 
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    const { isOpen: isThirdModalOpen, onOpen: onThirdModalOpen, onClose: onThirdModalClose } = useDisclosure();


    const statusColors = {
        1: { color: '#fff', bg: '#2E90FA' },
        2: { color: '#fff', bg: '#6172F3' },
        3: { color: '#00DC23', bg: '#00DC231A' },
        '-2': { color: '#F42529', bg: '#F425291A' },
        // Add more as needed
    };

    const getOrderStatus = (statusID) => {
        switch (statusID) {
          case 1:
            return 'Incoming';
          case 2:
            return 'Outgoing';
          case 3:
            return 'Success';
          case -2:
            return 'Cancelled';
          default:
            return 'NIL';
        }
    };

    // function formatPrice(price) {
    //     if (typeof price === 'string') {
    //       // Convert the string to a number before formatting
    //       price = parseFloat(price);
    //     }
      
    //     if (!isNaN(price)) {
    //       const formattedPrice = price.toLocaleString('en-US'); // Format as a number with commas
    //       return `₦${formattedPrice}`; // Add Naira symbol in front of the formatted price
    //     }
    //     return price;
    // }

    function formatPrice(price, additionalAmount = 0) {
        if (typeof price === 'string') {
            price = parseFloat(price);
        }
        if (!isNaN(price)) {
            const totalAmount = price + additionalAmount; // Add the additional amount
            const formattedPrice = totalAmount.toLocaleString('en-US'); // Format as a number with commas
            return `₦${formattedPrice}`; // Add Naira symbol in front of the formatted price
        }
        return price;
    }

    if (isLoadingData) {
        return <div>Loading....</div>
      }
    
      if (isErrorData) {
        return <div>Error Fetching Data</div>
      }

  return (
    <Box>
        <TableContainer>
          <Table variant='striped' colorScheme='tbrand'>
              <Thead > 
                  <Tr >
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'} >LPG Filling Plants</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Order Status</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Delivery Status</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Date</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Destination</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Quantity(MT)</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Price</Th>
                    <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Action</Th>
                  </Tr>
              </Thead>
              
              <Tbody  >
                  {
                      orderData.map((data) =>
                          <Tr key={data.OrderID} >
                              <Td fontWeight={'semibold'}  >
                                <Flex alignItems={'center'} >

                                    <Box>
                                        <Image
                                            boxSize='30px'
                                            objectFit='cover' src={EmeryAva} 
                                        />
                                    </Box>   
                                  <Box ml={'10px'}>
                                    {data.FullName}
                                  </Box>
                                </Flex>
                              </Td>
                              <Td textAlign={'center'} >
                                {statusColors[data.OrderStatusID] ? (
                                    <Box
                                      borderRadius={`full`}
                                      color={statusColors[data.OrderStatusID].color}
                                      bg={statusColors[data.OrderStatusID].bg}
                                      py={0.5}
                                      px={6}
                                      fontWeight={`semibold`}
                                      fontSize={`xs`}
                                      textAlign={'center'}
                                    >
                                      {getOrderStatus(data.OrderStatusID)}
                                    </Box>
                                  ) : (
                                    <Box>NIL</Box>
                                )}
                              </Td>
                              <Td textAlign={'center'} fontSize={`xs`} >NIL</Td>
                              <Td textAlign={'center'} fontSize={`xs`} >
                                {/* {data.OrderDate} */}
                                {data.OrderDate && (
                                  <>
                                    {data.OrderDate.split('T')[0]} 
                                    {/* {data.OrderDate.split('T')[1].slice(0, 5)} */}
                                  </>
                                )}
                              </Td>
                              <Td textAlign={'center'} fontSize={`xs`} maxW="150px" isTruncated >{data.Destination}</Td>
                              <Td textAlign={'center'} fontSize={`xs`} >{data.Quantity}</Td>
                              {/* <Td textAlign={'center'} fontWeight={'semibold'} >{formatPrice(data.TotalAmount)}</Td> */}
                              <Td textAlign={'center'} fontWeight={'semibold'} >{formatPrice(data.TotalAmount)}</Td>
                              <Td 
                                // onClick={() => {
                                //   setSelectedUserId(data.OrderID);
                                //   onSecondModalOpen();
                                // }}  
                                // cursor={'pointer'} 
                                fontWeight={'bold'} 
                                fontSize={'sm'} 
                                color={'primary.200'}  
                                textAlign={'center'} 
                              >
                                <Flex>
                                    <Text
                                        onClick={() => {
                                        setSelectedUserId(data.OrderID);
                                        onSecondModalOpen();
                                        }}  
                                        cursor={'pointer'}  
                                        mr={4}
                                    >
                                        WayBill
                                    </Text>
                                    <Text 
                                        onClick={() => {
                                        setSelectedUserId(data.OrderID);
                                        onThirdModalOpen();
                                        }}  
                                        cursor={'pointer'}
                                        ml={4}
                                    >
                                        Loading order
                                    </Text>
                                </Flex>
                               
                              </Td>
                          </Tr>           
                      )
                  }
              </Tbody>                
          </Table>
      </TableContainer>

        {/* WayBill */}
        <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} size={'4xl'} >
            <ModalOverlay />
            <ModalContent borderRadius={'3xl'}  background="linear-gradient( #e8f0f9 90%, #e8f0f9 95%, #e8f0f9 80%)"   width={'650px'}  >
            <ModalBody>

            {
                selectedUserId && 
                orderData.filter((data) => data.OrderID === selectedUserId) 
                    .map((data) => <Box key={data.OrderID}>

                        <Flex alignItems={'center'} p={8} >
                            <HStack>
                                <Image
                                boxSize='100px'
                                src={FullLogo} 
                                />  
                                <Box borderLeft={'1px solid #D6DCE5'} paddingLeft={5} marginLeft={3} >
                                    
                                    <Text>
                                        Invoice number:
                                    </Text>
                                    <Text fontWeight={'semibold'} fontSize={'base'} color={'primary.100'}  >
                                        Nº: 000027
                                    </Text>
                                    <Text pt={4}>
                                        Issued:
                                    </Text>
                                    <Text fontWeight={'semibold'} fontSize={'base'} color={'primary.100'}>
                                        {data.OrderDate && (
                                            <>
                                                {data.OrderDate.split('T')[0]} 
                                                {/* {data.OrderDate.split('T')[1].slice(0, 5)} */}
                                            </>
                                        )}
                                    </Text>

                                </Box>
                            </HStack>
                            <Box
                                position={'absolute'} 
                                top={'70px'} right={'8%'} 
                                borderRadius={`full`}
                                color={'primary.100'}
                                bg={'#fff'}
                                py={.5}
                                textAlign={'center'}
                                // px={6}
                                fontWeight={`medium`}
                                fontSize={`sm`}
                                w={'15%'}
                            >
                                WAYBILL
                            </Box>
                        </Flex>

                        <Box bg={'#fff'} w={"95%"} m={'auto'} borderRadius={'3xl'} p={8} mb={7}  >
                            <Flex alignItems={'center'} justifyContent={'space-between'}  >
                                <Box w={'48%'}  >
                                    <HStack pb={2} >
                                        <Image
                                            boxSize='15px'
                                            src={BlueTick} 
                                        />  
                                        <Text>
                                            Buyer Name
                                        </Text>
                                    </HStack>
                                    <Box bg={'#F6F8FC'} borderRadius={'3xl'} p={5} >
                                        
                                        <HStack  >
                                            <Image
                                                boxSize='20px'
                                                src={Element} 
                                            />  
                                            <Text fontWeight={'semibold'} fontSize={'base'} >
                                                {data.FullName}
                                            </Text>
                                        </HStack>
                                        <Text py={3} >
                                            (+234)903 123 1213
                                        </Text>
                                        <Text>
                                            92102, Lekki Drive, Ikoyi <br /> Lagos Nigeria.
                                        </Text>
                                    </Box>
                                    
                                </Box>
                                <Box w={'48%'} >
                                    <HStack pb={2}>
                                        <Image
                                            boxSize='15px'
                                            src={BlueTick} 
                                        />  
                                        <Text>
                                            Seller Name
                                        </Text>
                                    </HStack>
                                    <Box bg={'#F6F8FC'} borderRadius={'3xl'} p={5} >
                                        
                                        <HStack  >
                                            <Image
                                                boxSize='20px'
                                                src={Company} 
                                            />  
                                            <Text fontWeight={'semibold'} fontSize={'base'} >
                                                {data.VendorName}
                                            </Text>
                                        </HStack>
                                        <Text py={3} >
                                            (+234)903 123 1213
                                        </Text>
                                        <Text>
                                            92102, Lekki Drive, Ikoyi <br /> Lagos Nigeria.
                                        </Text>
                                    </Box>
                                    
                                </Box>
                            
                            </Flex>
                            <Box mt={8} >
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr bg={'#F6F8FC'} color={'primary.100'}  >
                                                <Th borderTopLeftRadius={20} >Item</Th>
                                                <Th >Price</Th>
                                                <Th >Qty</Th>
                                                <Th borderTopRightRadius={20} >Total</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        <Tr>
                                            <Td color={'primary.100'} fontWeight={'semibold'} >LPG Gas</Td>
                                            <Td>₦{data.TotalAmount}</Td>
                                            <Td>{data.Quantity}</Td>
                                            <Td color={'primary.100'} fontWeight={'semibold'}  >₦{data.TotalAmount}</Td>
                                        </Tr>
                                    
                                        </Tbody>
                                        
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Flex alignItems={'center'} justifyContent={'space-between'} mt={10}  >
                                <Box w={'45%'}>
                                    <Text color={'primary.100'} fontWeight={'semibold'} >
                                        Terms & Conditions:
                                    </Text>
                                    <Text color={'#868DA6'} fontSize={'10px'} >
                                        Fees and payment terms will be established in the 
                                        contract or agreement prior to the commencement of the 
                                        project. An initial deposit will be required before any 
                                        design work begins. We reserve the right to suspend or 
                                        halt work in the event of non-payment.
                                    </Text>
                                </Box>
                                <Box w={'50%'} fontSize={'12px'}  >
                                    <HStack alignItems={'center'} justifyContent={'space-between'}  >
                                        <Box fontWeight={'semibold'} color={'primary.100'}  >
                                            <Text>
                                                Subtotal:
                                            </Text>
                                            <Text>
                                                Discount <Text color={'#868DA6'} as={'span'}  >(Special Offer)</Text>:
                                            </Text>
                                            <Text>
                                                Charge Fee:
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Text>
                                                ₦{data.TotalAmount}
                                            </Text>
                                            <Text>
                                                ₦0
                                            </Text>
                                            <Text>
                                                ₦1000
                                            </Text>
                                        </Box>
                                    </HStack>
                                    <HStack alignItems={'center'} justifyContent={'space-between'}   bg={'#F6F8FC'} color={'primary.100'} py={4} px={2} fontWeight={'semibold'} borderTopRadius={10}  >
                                        <Text>
                                            Invoice Total:
                                        </Text>
                                        <Text>
                                        {formatPrice(data.TotalAmount, 1000)}
                                        </Text>
                                    </HStack>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
            )}
                


                {/* onClick={onSecondModalClose} */}


            </ModalBody>

            
            </ModalContent>
        </Modal>

        {/*LoadingOrder  */}
        <Modal isOpen={isThirdModalOpen} onClose={onThirdModalClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent p={0} borderRadius={'3xl'}  background="linear-gradient( #e8f0f9 90%, #e8f0f9 95%, #e8f0f9 80%)"   width={'650px'}  >
            <ModalBody p={0}>

            {
                selectedUserId && 
                orderData.filter((data) => data.OrderID === selectedUserId) 
                    .map((data) => <Box key={data.OrderID}  >

                        <Flex
                            bgImage={`url(${LoadingOrder})`}
                            bgSize="cover"
                            bgPosition="center"
                            bgRepeat="no-repeat"
                            // w="100%"
                            alignItems={'center'} 
                            p={8}  
                            // border={'1px solid red'}
                            borderTopRadius={'3xl'} 
                        >
                            <HStack>
                                <Image
                                boxSize='100px'
                                src={LoadingOrderLogo} 
                                />  
                                
                            </HStack>
                            <Box
                                position={'absolute'} 
                                top={'70px'} right={'8%'} 
                                borderRadius={`full`}
                                color={'primary.100'}
                                bg={'#fff'}
                                py={.8}
                                textAlign={'center'}
                                px={.8}
                                fontWeight={`medium`}
                                fontSize={`sm`}
                                w={'18%'}
                            >
                                Loading Order
                            </Box>
                        </Flex>

                        <Box bg={'#fff'} w={"95%"} m={'auto'} borderRadius={'3xl'} p={8} mb={7}  >
                            <Flex alignItems={'top'} justifyContent={'space-between'}  >
                                <Box w={'40%'}  >
                                    <HStack pb={2}>
                                          
                                        <Text  bg={'#E3EFFF'} color={'primary.100'} fontWeight={'medium'} >
                                            Vendor Name:
                                        </Text>
                                    </HStack>
                                    <Box  borderRadius={'3xl'} py={5} >
                                        
                                        <HStack  >                                             
                                            <Text fontWeight={'semibold'} fontSize={'base'} >
                                                {data.VendorName}
                                            </Text>
                                        </HStack>
                                        <Text py={3} color={'border.600'} >
                                            (+234)903 123 1213
                                        </Text>
                                        <Text color={'border.600'}>
                                            92102, Lekki Drive, Ikoyi <br /> Lagos Nigeria.
                                        </Text>
                                    </Box>
                                    
                                </Box>
                                <Box w={'35%'} >
                                    <HStack pb={2}>
                                       
                                        <Text  bg={'#E3EFFF'} color={'primary.100'} fontWeight={'medium'}>
                                            Date:
                                        </Text>
                                    </HStack>
                                    <Box borderRadius={'3xl'} py={5} >
                                        
                                        <HStack  >                                             
                                            <Text fontWeight={'semibold'} fontSize={'base'} >
                                            {data.OrderDate && (
                                            <>
                                                {data.OrderDate.split('T')[0]} 
                                                {/* {data.OrderDate.split('T')[1].slice(0, 5)} */}
                                            </>
                                            )}
                                            </Text>
                                        </HStack>
                                        <Text py={1} color={'border.600'} >
                                            LPG
                                        </Text>
                                        <Text py={1} color={'border.600'} >
                                            (+234)903 123 1213
                                        </Text>
                                        <Text color={'border.600'} >
                                            92102, Lekki Drive, Ikoyi <br /> Lagos Nigeria.
                                        </Text>
                                    </Box>
                                    
                                </Box>

                                <Box w={'25%'} >
                                    <HStack pb={2}>                                        
                                        <Text  bg={'#E3EFFF'} color={'primary.100'} fontWeight={'medium'} >
                                            Invoice number:
                                        </Text>
                                    </HStack>
                                    <Box borderRadius={'3xl'} py={5} >
                                            
                                      
                                        <Text fontWeight={'semibold'} fontSize={'base'} color={'primary.100'}  >
                                            Nº: 000027
                                        </Text>
                                    </Box>
                                    
                                </Box>
                            
                            </Flex>
                            <Box mt={8} >
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr bg={'#F6F8FC'} color={'primary.100'}  >
                                                <Th borderTopLeftRadius={20} w={'80%'}  >Item</Th>
                                                {/* <Th >Price</Th> */}
                                                {/* <Th >Qty</Th> */}
                                                <Th borderTopRightRadius={20}  >QTY</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        <Tr>
                                            <Td color={'primary.100'} fontWeight={'semibold'} >LPG Gas</Td>
                                            <Td color={'primary.100'} fontWeight={'semibold'}  >{data.Quantity}</Td>
                                        </Tr>
                                    
                                        </Tbody>
                                        
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Flex alignItems={'center'} justifyContent={'space-between'} mt={10}  >
                                <Box w={'45%'}>
                                    <Text color={'primary.100'} fontWeight={'semibold'} >
                                        Terms & Conditions:
                                    </Text>
                                    <Text color={'#868DA6'} fontSize={'10px'} >
                                        Fees and payment terms will be established in the 
                                        contract or agreement prior to the commencement of the 
                                        project. An initial deposit will be required before any 
                                        design work begins. We reserve the right to suspend or 
                                        halt work in the event of non-payment.
                                    </Text>
                                </Box>
                                
                            </Flex>
                        </Box>

                        <Box
                            bgImage={`url(${LoadingOrderFooter})`}
                            bgSize="cover"
                            bgPosition="center"
                            bgRepeat="no-repeat"
                            // w="100%"
                            alignItems={'center'} 
                            p={8}  
                            textAlign={'center'}                               

                            // border={'1px solid red'}
                            borderBottomRadius={'3xl'} 
                        >
                           
                            <Text
                             
                                color={'border.600'}                                
                                py={.8}
                                textAlign={'center'}                               
                                fontSize={`xs`}
                            >
                               www.myeasygas.com
                            </Text>
                            <Text
                                color={'border.600'}                                
                                py={.8}
                                textAlign={'center'}                               
                                fontSize={`xs`}
                            >
                                contact@myeasygas.com / (+234)903 123 1213
                            </Text>
                        </Box>
                    </Box>
            )}
                


                {/* onClick={onSecondModalClose} */}


            </ModalBody>

            
            </ModalContent>
        </Modal>

    </Box>
  )
}

export default AllOrder