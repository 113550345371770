import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale, //x-axis
    LinearScale, //y-axis
    Tooltip,
    Filler,
    // Legend
} from "chart.js";
// import { Box } from "@chakra-ui/react";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Filler);

export const BarChart = () => {

    const data = {
    labels: [
      "Sept 1-7",
      "Sept 8-14",
      "Sept 15-21",
      "Sept 22-28"
    ],
    datasets: [
        {
          label: "Report Analysis",
          data: [0, 0, 0, 0],
          fill: true,
          backgroundColor: "#049541",
          borderRadius: 5,
          borderWidth: 1,
          // categoryPercentage: 0.5,
          tension: 0.4,
        },
    ],
  };

  const options = {
    responsive: true,
    barThickness: 30,
    maintainAspectRatio: false,
    height: "200px",
    scales: {
      x: {
        ticks: {
          color: "#9D9B9B",
          fontSize: 10,
          fontStyle: "bold",
        },
        grid: {
          display: false,
        },
      },

      y: {
        min: 0,
        max: 12,
        ticks: {
          stepSize: 3,
          // autoSkip: false,
          display: false
        },
        grid: {
          // color: "#E6E6E6",
          // lineWidth: 1,
          // tickColor: "#000", // for the tick mark
          // tickBorderDash: [2, 3], // also for the tick, if long enough
          // tickLength: 10, // just to see the dotted line
          tickWidth: 0, 
        },
        border: {
        //   dash: [4, 4],
          color: "transparent",
        },
        beginAtZero: true,
      },
    },
  };
  return (
      // <Box>
        <Bar data={data} options={options}/>
      // </Box>
    )
}
