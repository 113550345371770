import { Stack, HStack, Text, Box, Icon, Flex, Tabs, Tab, TabList, TabPanels, TabPanel, Image, VStack, Divider, Heading, Checkbox, Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, Select } from "@chakra-ui/react";
import { BasicInput } from "../../../shared/BasicInput";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { 
    Phone,  
    Email,
    TruckGrey,
    Add,
    Trucking,
    GreenTruck,
    Close,
    BackIcon
} from "../../../../Assets";
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';
import { useContext, useState } from "react";
import AuthContext from "../../../../Components/Admin/Contexts/AdminContext";


export function DeliveryDetailForm(props) {


    const { checkoutData, setCheckoutData } = useContext(AuthContext)
    const { validatedTruckerData,isLoadingTrucker,isErrorTrucker,} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // console.log(salesRepsProfileData)

    const openNewTab = (url) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) newWindow.focus();
    };
    
  
    const handleNewPrivateDelivery = () => {
        // console.log(props.data)
        // console.log(salesRepsProfileData)
        
        const newPrivateDelivery = {
            RegistrationNumber: "",
            VehicleCapacity: "",
            PhoneNumber: "",
            EmailAddress: "",
            salesRepID:props.selectedOptionId
        }

        props.updateData({
            ...props.data,
            PrivateDelivery: [...props.data.PrivateDelivery, newPrivateDelivery]
        });


    }

    if (isLoadingTrucker ) {
        return <div>Loading....</div>
    }
    
    if (isErrorTrucker ) {
    return <div>Error Fetching Data</div>
    }

    if (props.isLoadingSalesRepsProfileData) {
        return <div>Loading Sales....</div>
    }

    if (props.isErrorSalesRepsProfileData ) {
        return <div>Error Fetching Sales Data</div>
    }

    return (
        <>
            <StepInfo currentStep={2} title={"Delivery Details"} desc={"Choose your preferred Delivery channel"}/>
           
            <Stack
                // spacing={4} 
                overflowY={`scroll`}
                maxH={props.height}
                scrollBehavior={`auto`}
                // ps={10}
                // pe={16}
                py={4}
            >
                <Text ml={10} fontWeight={'bold'} fontSize={'base'}  >
                    Delivery Type            
                </Text>

                <Tabs variant="unstyled" bg="#fff" m="0px 20px 20px 20px" borderRadius="lg" pt={5}>
                    <TabList ml={'20px'}>
                       
                        <Tab
                            color="primary.400"
                            position="relative"
                            border="1px solid #B5B4B4"
                            borderRadius= "10px"
                            marginRight='20px'
                            fontSize={'sm'}
                            width={'180px'}
                            height={'50px'}
                            _selected={{
                                color: "primary.200",
                                border: "1px solid #049541",
                                fontWeight: "semibold",
                                borderRadius: "10px",
                                backgroundColor: "font.300"
                            }}
                            ///i dont know why this is not updating the main data, will sent it to 2 by default, for now.
                            onClick={() => {
                                props.updateData({ DeliveryType: 1 })
                                console.log(props.DeliveryType)
                            }}
                        >                

                            <HStack alignItems="center" position="relative">
                                
                                <Icon
                                    boxSize="20px"
                                    viewBox="0 0 50 50"
                                    position="absolute"
                                    top="3px"
                                    left="-20px"
                                    as={props.DeliveryType === 2 ? Trucking : GreenTruck} 
                                />                            
                                <Text ml={2} >LPG Transporter</Text>
                            </HStack>                            
                        </Tab>
                        
                        <Tab
                            color="primary.400"
                            position="relative"
                            border="1px solid #B5B4B4"
                            borderRadius= "10px"
                            fontSize={'sm'}
                            width={'180px'}
                            height={'50px'}
                            _selected={{
                                color: "primary.200",
                                border: "1px solid #049541",
                                fontWeight: "semibold",
                                borderRadius: "10px",
                                backgroundColor: "font.300"
                            }}
                            ///i dont know why this is not updating the main data, will sent it to 2 by default, for now.
                            onClick={() => {
                                props.updateData({ DeliveryType: 2 })
                                console.log(props.DeliveryType)
                            }}
                        >
                            <HStack alignItems="center" position="relative">
                                
                                <Icon
                                    boxSize="20px"
                                    viewBox="0 0 50 50"
                                    position="absolute"
                                    top="3px"
                                    left="-20px"
                                    as={props.DeliveryType === 1 ? Trucking : GreenTruck} 
                                />                                   
                                                           
                                <Text ml={2} >Private Delivery</Text>
                            </HStack>
                            
                        </Tab>
                    </TabList>
 
                    <TabPanels>

                        <TabPanel>
                            <Text fontSize={`base`} py={5} >
                                Select One below:
                            </Text>
                            {
                                validatedTruckerData.map((data) =>
                                
                                <Box
                                    key={data.CustomerId}     
                                                    
                                >
                                <HStack>
                                    <Image
                                        boxSize='50px'
                                        objectFit='cover' src={EmeryAva} 
                                    />                         
                                    <Box
                                        ms={1}
                                        w={`70%`}
                                    >
                                        <Text
                                            fontWeight={`semibold`}
                                            fontSize={`base`}
                                        >
                                            {data.BusinessName}
                                        </Text>
                                        <Flex>
                                            <Text
                                                fontSize={`xs`}
                                                pe={4}
                                            >
                                            Cumm. Vehicle Capacity: 
                                            </Text>
                                            <Text
                                                color={`primary.200`}
                                                fontWeight={`bold`}
                                                fontSize={`sm`}
                                            >
                                                {data.vehicleCapacity}44 tons
                                            </Text>
                                        </Flex>
                                        <Flex>
                                            <Text
                                                fontSize={`xs`}
                                                pe={4}
                                            >
                                                {data.HomeAddress}, {data.LocalGovernment}, {data.CustomerState}
                                            </Text>
                                           
                                        </Flex>
                                    </Box>
                                    <VStack>
                                        <Text
                                            fontSize={`11px`}
                                            textAlign={`center`}
                                        >
                                            <Text as={'span'} fontWeight={'bold'} fontSize={'base'}  >
                                                {data.Vehicle}₦100
                                            </Text>
                                            /tons
                                        </Text>
                                        
                                    </VStack>
                                </HStack>
                                <Box position='relative' py={3} w={`100%`} >
                                <Divider />
                                </Box>
                                </Box>

                                )
                            }
                        </TabPanel>
                        
                        <TabPanel>
                        
                            <Stack
                                spacing={8} 
                                overflowY={`scroll`}
                                scrollBehavior={`auto`}
                                py={4}
                            >
                                {props.data.PrivateDelivery.map((data, i) => {
                                    return <Box h={`100%`} w={`100%`} key={i}>
                                            <HStack
                                                pb={7}
                                                justify={`space-between`}
                                            >
                                            <Heading size={'lg'}>
                                                Vehicle {i + 1}
                                            </Heading>
                                            {i !== 0 && <Icon
                                                boxSize={'20px'}
                                                onClick={() => {
                                                    const updatedPrivateDelivery = [...props.data.PrivateDelivery];

                                                    updatedPrivateDelivery.splice(i, 1);

                                                    props.updateData({ PrivateDelivery: updatedPrivateDelivery });
                                                }}
                                            >
                                                <Close />
                                            </Icon>}
                                        </HStack>
                                        <HStack gap={10} pb={10} align={`start`}>
                                           <BasicInput
                                                type={'text'}
                                                focus={{ bg: 'primary.900' }}
                                                for={'RegistrationNumber'}
                                                text={'Registration Number'}
                                                value={data.RegistrationNumber}
                                                updateData={props.updateData}
                                                icon={<TruckGrey />}
                                                isCheckout
                                                data={props.data}
                                                step={i}
                                           />
                                            <BasicInput
                                                type={'number'}
                                                focus={{ bg: 'primary.900' }}
                                                for={'VehicleCapacity'}
                                                text={'Vehicle Capacity'}
                                                value={data.VehicleCapacity}
                                                updateData={props.updateData}
                                                icon={<TruckGrey />}
                                                isCheckout
                                                data={props.data}
                                                step={i}
                                           />
                                        </HStack>
                                        <HStack spacing={10}>
                                            <BasicInput
                                                type={'tel'}
                                                focus={{ bg: 'primary.900' }}
                                                for={'PhoneNumber'}
                                                text={'Phone Number'}
                                                value={data.PhoneNumber}
                                                updateData={props.updateData}
                                                icon={<TruckGrey />}
                                                isCheckout
                                                data={props.data}
                                                step={i}
                                           />
                                            <BasicInput
                                                type={'email'}
                                                focus={{ bg: 'primary.900' }}
                                                for={'EmailAddress'}
                                                text={'Email Address'}
                                                value={data.EmailAddress}
                                                updateData={props.updateData}
                                                icon={<TruckGrey />}
                                                isCheckout
                                                data={props.data}
                                                step={i}
                                           />
                                        </HStack>
                                        <Box py={10}>
                                            <Select onChange={(event) => props.handleOptionSelect(event.target.value)} >
                                                <option value="">Select Sales Representative</option>
                                                {props.salesRepsProfileData && props.salesRepsProfileData.map((data) => (
                                                    <option key={data.salesRepID} value={data.salesRepID}>
                                                        {data.salesRepName}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                })}
                            </Stack>

                            <HStack>
                                <Icon
                                    onClick={handleNewPrivateDelivery}
                                >
                                    <Add />
                                </Icon>
                                <Text
                                    fontWeight={`bold`}
                                    onClick={() => props.updateData({ DeliveryType: 2 })}
                                    
                                >
                                    Add Another Vehicle
                                </Text>
                            </HStack>

                        </TabPanel>

                    </TabPanels>

                    <Checkbox
                    top={0}
                    left={0}
                    required
                    isChecked={props.termsAndConditions}
                    onChange={e => {
                        props.updateData({ termsAndConditions: e.target.checked })
                    }}
                    >
                        <Center>
                            <Text>I agree to LPG</Text>&nbsp;
                            <Text fontWeight={`semibold`} color={'primary.100'}  onClick={() => openNewTab('/#/terms')}>Terms & Condition</Text>
                            &nbsp;<Text>&</Text>&nbsp;
                            <Text fontWeight={`semibold`} color={'primary.100'} onClick={() => openNewTab('/#/terms')} >Privacy Policy</Text>
                        </Center>
                    </Checkbox>
                </Tabs>               
                

               
            </Stack>

           

        </>
    );
}

DeliveryDetailForm.prototype = {
    height: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    vehicleCapacity: PropTypes.string.isRequired,
    registrationNumber: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.string.isRequired,

}
