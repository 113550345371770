import { Box, Flex, HStack, Icon, Image, Input, InputGroup, InputRightElement, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import RightArrow  from '../../../../Assets/RightArrow.svg'
import { Search } from '../../../../Assets'
import ArrowButton from '../../../shared/Button'
import { ProductModal } from '../Modals/ProductModal'
import { VendorDashboardContext } from '../context/VendorDashboardContext'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext'

const Product = () => {

    const [searchQuery, setSearchQuery] = useState("");
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { setShowAddProduct ,setShowEditProduct,showAddProduct, showEditProduct } = useContext(VendorDashboardContext);
    const { vendorProductData,isLoadingVendorProduct,isErrorVendorProduct,refetchVendorProduct} = useContext(AuthContext);

    

    const filteredData = vendorProductData && vendorProductData.filter((data) =>
        data.ProductName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (isLoadingVendorProduct) {
        return <div>Loading....</div>
    }
      
    if (isErrorVendorProduct) {
        return <div>Error Fetching Data</div>
    }

    filteredData.sort((a, b) => {
        const dateA = new Date(a.DateCreated);
        const dateB = new Date(b.DateCreated);
        return dateB - dateA; // Sort in descending order (newest first)
    });

    const handleOpenEditModal = (productId) => {
        console.log("work",productId);
      setSelectedUserId(productId);
      setShowEditProduct(true)
    }
   
    const handleOpenAddModal = () => {        
      setShowAddProduct(true)
    }

    const selectedProduct = filteredData.find(product => product.ProductID === selectedUserId);

    function formatPrice(price) {
        const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
        if (!isNaN(numericPrice)) {
          const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
          return `${formattedPrice}`; // Add Naira symbol in front of the formatted price
        }
        return price;
    }
  

  return (
    <Box bg='primary.600' p={'20px 20px'}>

        <HStack
            alignItems={`normal`}
            justifyContent={'space-between'}
            p={4}
            height={`100%`}
            // border={'1px solid red'}
        >
       
            
    
        <Flex alignItems="center" mr={'20px'}>
            <InputGroup w={'250px'}>                       
              <Input 
                placeholder='Search Users.....' 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <InputRightElement>
                <Icon boxSize='25px'>
                  <Search/>
                </Icon>
              </InputRightElement>
            </InputGroup>
        </Flex>
        <Flex
            h={`80%`}
            alignItems={`center`}
            justifyContent={'flex-end'}
        >
            <VStack>
           
            <ArrowButton
                size='xs'
                isIcon={false}
                text='Add Product'
                variant={`active`}
                onClick={handleOpenAddModal}
            />
            </VStack>
        </Flex>
      
        </HStack>

        <TableContainer>
            <Table variant='unstyled' >
                <Thead backgroundColor={'primary.100'}>
                    <Tr>
                        <Th borderBottom={'1px solid #E8EEFF'} borderTopLeftRadius={'2xl'} color={'brand.300'} >PRODUCT NAME</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >PRICE PER METRIC TON (₦) </Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >QUANTITY (MT)</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >DESCRIPTION</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >DISCOUNT</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} borderTopRightRadius={'2xl'}  color={'primary.100'}  textAlign={'center'} >.</Th>
                    </Tr>
                </Thead>
                    
                <Tbody>
                    {
                        filteredData.map((data, index) =>
                        <Tr key={data.ProductID} bg={index % 2 === 0 ? 'white' : 'tbrand.100'} >
                            <Td fontWeight={'semibold'} >                               
                                <Text ml={'10px'} fontWeight={'semibold'}  >
                                    {data.ProductName}
                                </Text>                            
                            </Td>
                            <Td textAlign={'left'} fontWeight={'semibold'} >{formatPrice(data.Price)}</Td>
                            <Td textAlign={'left'} fontWeight={'semibold'} >{data.Quantity}</Td>
                            <Td textAlign={'left'} fontWeight={'medium'} maxW="150px" isTruncated >{data.ProductDescription}</Td>
                            <Td textAlign={'left'} fontWeight={'semibold'}  >{data.Discount}</Td>
                            <Td 
                                onClick={() => handleOpenEditModal(data.ProductID)} 
                                cursor={'pointer'} 
                                fontWeight={'bold'} 
                                fontSize={'sm'} 
                                color={'primary.200'}  
                                textAlign={'right'} 
                            >
                                <Image
                                boxSize='30px'
                                objectFit='cover' src={RightArrow} 
                                float={'right'}
                                />
                            </Td>
                        </Tr>           
                    )
                    }
                </Tbody>          
            </Table>
        </TableContainer>

        {showAddProduct && <ProductModal />}
        {showEditProduct && <ProductModal selectedUserId={selectedUserId} selectedProduct={selectedProduct} isLoadingVendorProduct={isLoadingVendorProduct} refetchVendorProduct={refetchVendorProduct} />}
    </Box>
  )
}

export default Product